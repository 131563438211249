@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');


@font-face {
    font-family: 'Goldman bold';
    src: url('../font/Goldman-Bold.eot');
    src: url('../font/Goldman-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/Goldman-Bold.woff2') format('woff2'),
        url('../font/Goldman-Bold.woff') format('woff'),
        url('../font/Goldman-Bold.ttf') format('truetype'),
        url('../font/Goldman-Bold.svg#Goldman-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldman';
    src: url('../font/Goldman-Regular.eot');
    src: url('../font/Goldman-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/Goldman-Regular.woff2') format('woff2'),
        url('../font/Goldman-Regular.woff') format('woff'),
        url('../font/Goldman-Regular.ttf') format('truetype'),
        url('../font/Goldman-Regular.svg#Goldman-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldman bold sans';
    src: url('../font/GoldmanSans-Bold.eot');
    src: url('../font/GoldmanSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/GoldmanSans-Bold.woff2') format('woff2'),
        url('../font/GoldmanSans-Bold.woff') format('woff'),
        url('../font/GoldmanSans-Bold.ttf') format('truetype'),
        url('../font/GoldmanSans-Bold.svg#GoldmanSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldman regular sans';
    src: url('../font/GoldmanSans-Regular.eot');
    src: url('../font/GoldmanSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/GoldmanSans-Regular.woff2') format('woff2'),
        url('../font/GoldmanSans-Regular.woff') format('woff'),
        url('../font/GoldmanSans-Regular.ttf') format('truetype'),
        url('../font/GoldmanSans-Regular.svg#GoldmanSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldman sans thin';
    src: url('../font/GoldmanSans-Thin.eot');
    src: url('../font/GoldmanSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('../font/GoldmanSans-Thin.woff2') format('woff2'),
        url('../font/GoldmanSans-Thin.woff') format('woff'),
        url('../font/GoldmanSans-Thin.ttf') format('truetype'),
        url('../font/GoldmanSans-Thin.svg#GoldmanSans-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldman Sans medium';
    src: url('../font/GoldmanSans-Medium.eot');
    src: url('../font/GoldmanSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/GoldmanSans-Medium.woff2') format('woff2'),
        url('../font/GoldmanSans-Medium.woff') format('woff'),
        url('../font/GoldmanSans-Medium.ttf') format('truetype'),
        url('../font/GoldmanSans-Medium.svg#GoldmanSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Goldman sans black';
    src: url('../font/GoldmanSans-Black.eot');
    src: url('../font/GoldmanSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../font/GoldmanSans-Black.woff2') format('woff2'),
        url('../font/GoldmanSans-Black.woff') format('woff'),
        url('../font/GoldmanSans-Black.ttf') format('truetype'),
        url('../font/GoldmanSans-Black.svg#GoldmanSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldman sans light';
    src: url('../font/GoldmanSans-Light.eot');
    src: url('../font/GoldmanSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/GoldmanSans-Light.woff2') format('woff2'),
        url('../font/GoldmanSans-Light.woff') format('woff'),
        url('../font/GoldmanSans-Light.ttf') format('truetype'),
        url('../font/GoldmanSans-Light.svg#GoldmanSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: feather;
    src: url(../font/feather.eot);
    src: url(../font/feather.eot) format('embedded-opentype'), url(../font/feather.ttf) format('truetype'), url(../font/feather.woff) format('woff'), url(../font/feather.svg) format('svg');
    font-weight: 400;
    font-style: normal
}

[class*=" ft-"],
[class^=ft-] {
    font-family: feather !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0; font-family: inherit; font-size: 100%; font-style: inherit; font-weight: inherit;
    margin: 0; outline: 0; padding: 0; vertical-align: baseline;
}
body{ 
    background: #fff; font-size: 16px; line-height: 1.3; font-family: Goldman; font-weight: normal; 
    font-style: normal; color:#000; overflow-x: hidden; word-wrap: break-word; 
    -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}
:focus {
    outline: 0;
}
 
*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.clear,
.clearfix {
    clear: both;
}
.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-weight: 700;
}
.clear{ clear:  both;}
a{ color: #000; transition: all 400ms; -webkit-transition: all 400ms; cursor: pointer; outline: none; text-decoration: none;}
a:hover{ color: #f9951d; outline: none; text-decoration: none;}
a:focus, a:active{color: inherit; outline: none; text-decoration: none;}
img {max-width: 100%; height: auto; vertical-align: top;}
p{ margin: 0 0 15px;}  
ol, ul {list-style: none; margin: 0; padding: 0px;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    margin: 0px 0px 25px 0px; padding: 0px; font-weight: 600; line-height: 1.2; color: #000;
}

.account-wrapper {
    width: 600px;  position: absolute;  top: 50%;  left: 50%;  transform: translate(-50%, -50%);
    padding: 70px; border-radius: 6px;  background-color: #fff;  box-shadow: 1px 0 36px rgba(0,0,0,0.14);
}
.account-title-warpper{text-align: center;}

.account-title-warpper h2.title {text-align: center;  text-transform: uppercase; font-weight: 600;font-size:22px}
.account-title-warpper img{text-align: center;margin-bottom: 40px;width: 210px;}
.account-forgot-psw{margin-bottom: 10px;}
.loading{ position: relative; min-width: 100px; min-height: 100vh;}
.loading:after {
  content: ""; background: url(../images/loading-pg.gif) center/100px no-repeat;
  background-size: 100%; position: absolute;left: auto;right: 20%;width: 30px; top: 0; bottom: 0; border-radius: 40px;
}
.loading_data{ min-width: initial; min-height: initial;}

.btn span.load-data
{
    position: absolute !important;
    top: -2px;
    left: 0;
    width: 100%;
    height: 50px;
    display: inline-block;
    color: #f15922;
    background: url(../images/loading-pg.gif) 47px center no-repeat #fff;
    background-size: 30px;
    -webkit-background-size: 30px;
    line-height: 50px;
    padding-left: 30px;
}

.add_button button {float: right;}

/*--- Btn--- */
input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button,
.btn {
    min-width: 160px;
    height: 55px;
    color: #fff;
    /*display: inline-block;*/
    border-radius: 0px;
    margin: 0px 0px 15px;
    padding: .75rem 1.5rem;
    cursor: pointer;
    background: #3E3E3C;
    border: 0px;
    font-size: 20px;    
    text-transform: uppercase;
    line-height: 1.25;
    text-align: center;
    overflow: hidden;
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
        font-weight: 600;
    letter-spacing: .8px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    font-family: 'Goldman';
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus{outline: none;}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover,
.btn:hover {
    color: #fff;
    background: #3E3E3C;
    /* box-shadow: 0 1px 2px 0 rgb(105 103 206 / 45%), 0 1px 3px 1px rgb(105 103 206 / 30%); */
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select,
.form-control { 
    padding: 12px 20px;
    border: 1px solid #000;
    width: 100%;
    margin: 0 0 15px;
    background: #fff;
    border-radius: 0px;
    height: 48px;
    font-size: 16px;
    color: #000;
    font-family: Goldman;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.25rem;
}
textarea{ resize: both; outline: 0;}
/*--- table--- */
table{ width: 100%; max-width: 100%; margin-bottom: 20px; }
table td, table th {padding: 10px; vertical-align: middle; text-align:center;}

.form-control:focus{    
    color: #000;
    border-color: #000;
    outline: 0;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;}

/* ---  Header --- */
.header {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    height: 80px;
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 10;
    background: #3E3E3C;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    /*border-bottom: 1px solid #b7b7b7;*/
/*    background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
    background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
    background-image: -moz-linear-gradient(left, #a376fc, #f96f9b);
    background-image: -o-linear-gradient(left, #a376fc, #f96f9b);
    background-image: linear-gradient(to right, #a376fc, #f96f9b);
    background-repeat: repeat-x;*/
}
.navbar-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;    
    align-items: center;
}
.logo-box {
    height: 55px;
    width: 240px;
    background: #000;        
    position: relative;
    padding: 17px 0;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;   
    border-bottom: 1px solid #494b4c;
    text-align: center;
}
.logo-box a{font-size:32px; font-weight:bold; color:#fff;}
.header-cnt {
    display: flex; height: 55px; width: calc(100% - 240px); align-items: center;
    padding: 0px 30px 0px 10px; justify-content: space-between; transition: .3s ease all;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.header-cnt-left{
    margin-left: 390px;
}

.toggle-open.header-cnt-left{
    margin-left: 90px;
}

.logo-box .logo-sm {display:none; transition: 0.3s;}
.logo-box .logo-lg{display:block; transition: 0.3s;}
.logo-box.active {width: 75px;} 
.logo-box.active + .header-cnt {width: calc(100% - 75px);}
.logo-box.active .logo-sm {display:block;}
.logo-box.active .logo-lg {display:none;}   
.header .menu-icon {display: inline-block; width: 22px;z-index: 99;}
.header .menu-icon span { background-color: #fff; display: block; height: 3px; margin-bottom: 6px; transition: all .5s;}
.header .menu-icon span:last-child{margin-bottom:0px;}

.menu-icon.active .icon_menu1 {
    -webkit-transform: translateY(12px) rotate(45deg) scale(1.3); transform: translateY(12px) rotate(45deg) scale(1.3); 
}
.menu-icon.active .icon_menu2 {
    -webkit-transform: translate(0) rotate(0deg) scale(0); transform: translate(0) rotate(0deg) scale(0); 
}
.menu-icon.active .icon_menu3 {
    -webkit-transform: translateY(-4px) rotate(-45deg) scale(1.3); transform: translateY(-4px) rotate(-45deg) scale(1.3); 
}
.header .r-icon{font-size: 20px; position:relative; color: #42425a; margin-right: 30px;}
.header .bedge {
    background: #f3a00a; display: inline-block; width: 20px; height: 20px;
    border-radius: 100px; font-size: 14px; position: absolute; top: -7px;
    left: 10px; text-align: center; line-height: 20px;
}
.header .notity-icon.r-icon .bedge {background: #FF7588;}
.header-cnt-left {display: flex; align-items: center;}
.search-box {margin-left: 20px; position: relative;}
.search-box .form-control {
    padding: 12px 22px 12px 44px; border: 1px solid #000; width: 100%; margin: 0 0 0px; background: #fff;
    height: 44px; font-size: 16px; color: #000; border-radius: 10px;
}

.settings .form-group span{font-size: 22px;}

.search-icon {
    display: inline-block; position: absolute; top: 1px; background: #fff; left: 1px; border-radius: 10px;
    padding: 9px 9px 3px;
}
.myaccount {margin-top: 8px; position: relative;}
.myaccount-inner {display: flex;}
.myaccount-inner .user-name {font-size: 18px; display: inline-block; margin-right: 15px;text-transform: capitalize;color: #fff;}
ul.user-menu {
    top: 65px; right: 20px; width: 360px; height: 240px; padding: 15px; background: #FFFFFF;
    box-shadow: 3px 3px 3px #00000029; border: 1px solid #dedede; border-radius: 15px;
    opacity: 0; visibility: hidden; position: absolute;
}
ul.user-menu.active {opacity: 1; visibility: visible;}
ul.user-menu li {padding: 8px 0px;}
ul.user-menu li a{position: relative; padding-left: 35px;}
ul.user-menu .icon {position: absolute; left: 0px; top: -2px;}
.icon{background-size:contain; background-repeat: no-repeat !important; height:30px; width:30px; display: inline-block;}
.header-cnt .icon{height:22px; width:22px;}
.sc-icon { background: url(../images/Search.svg); background-size:contain;}
.ck-icon { background: url(../images/Alarm.svg); background-size:contain;}
.a-icon { background: url(../images/Alert.svg); background-size:contain;}
.u-icon { background: url(../images/User.svg); background-size:contain;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.profile-icon { background: url(../images/Profile.svg); background-size:contain;}
.color-icon { background: url(../images/Color.svg); background-size:contain;}
.logout-icon { background: url(../images/Logout.svg); background-size:contain;}
.password-icon { background: url(../images/password.svg); background-size:contain;}


.dashboard-icon{ background: url(../images/Dashboard.png); background-size:contain;}
.file-icon{ background: url(../images/Files.svg); background-size:contain;}
.docs-icon{ background: url(../images/Documents.svg); background-size:contain;}
.letter-icon{ background: url(../images/Letters.svg); background-size:contain;}
.comm-icon{ background: url(../images/Communications.svg); background-size:contain;}
.ac-icon{ background: url(../images/Accounts.svg); background-size:contain;}
.setting-icon{ background: url(../images/Settings.svg); background-size:contain;}
.history-icon{ background: url(../images/history.svg); background-size:contain;}
.form-icon{ background: url(../images/form-entries.svg); background-size:contain;}
.font-icon{ background: url(../images/font-1.png); background-size:contain;}
.userlist-icon{ background: url(../images/user-list.png); background-size:contain;}
.driver-icon{ background: url(../images/driver.svg); background-size:contain; height: 54px; width: 54px;}
.company-icon{ background: url(../images/company1.png); background-size:contain;}
.studentlist-icon{ background: url(../images/student.svg); background-size:contain; height: 34px; width: 34px;}
.university-icon{ background: url(../images/university.svg); background-size:contain; height: 34px; width: 34px;}
.fund-icon{ background: url(../images/fund.svg); background-size:contain; height: 34px; width: 34px;}
.category-icon{ background: url(../images/category.png); background-size:contain; height: 34px; width: 34px;}
.product-icon{ background: url(../images/product.png); background-size:contain; height: 34px; width: 34px;}
.cupoon-icon{ background: url(../images/discount.svg); background-size:contain; height: 35px; width: 35px;}



.vehicletype-icon{ background: url(../images/bus-type.svg); background-size:contain;}
.vehicle-icon{ background: url(../images/bus.svg); background-size:contain;}
.completed-icon{ background: url(../images/completed.svg); background-size:contain;}
.incompleted-icon{ background: url(../images/incompleted.svg); background-size:contain;}
.locate-icon{ background: url(../images/road.svg); background-size:contain;}
.events-icon{ background: url(../images/events.png); background-size:contain;}
.groups-icon{ background: url(../images/groups-2.png); background-size:contain;}
.routes-icon{ background: url(../images/routes.png); background-size:contain;}
.helpers-icon{ background: url(../images/helpers2.png); background-size:contain;  height: 40px; width: 40px;}
.template-icon{ background: url(../images/email.png); background-size:contain;}
.cms-icon{ background: url(../images/cms.png); background-size:contain;}

.packages-icon{ background: url(../images/packages.png); background-size:contain;}
.export-icon{
    background: url(../images/export.svg); background-size:contain; height:15px; width:15px;
}
.import-icon{
    background: url(../images/import.svg); background-size:contain; height:15px; width:15px;
}
.export-class{
    position: absolute;
    left: 15px;
    top: 0px;
    bottom: 0;
    margin: auto 0;
}
.img_class{height:90px;}

.form-group.z-index-999{
    position: relative;
    z-index: 999;
}

.form-group.z-index-998{
    position: relative;
    z-index: 998;
}

.form-group.z-index-997{
    position: relative;
    z-index: 997;
}

.form-group.z-index-996{
    position: relative;
    z-index: 996;
}

.form-group.z-index-995{
    position: relative;
    z-index: 995;
}

.form-group .css-yk16xz-control {height: 48px;
    border-color: hsl(0, 0%, 0%);
    border-radius: 0px;
    margin-bottom:20px
}
.form-group .css-yk16xz-control:hover{
    border-color: hsl(0, 0%, 0%);
}
.form-group .css-g1d714-ValueContainer{
    height: 68px;
    padding: 2px 0px;
}
.css-1hb7zxy-IndicatorsContainer{height: 32px;}
.form-group .css-1okebmr-indicatorSeparator{
    display:none
}
.form-group .css-6q0nyr-Svg{
    width:20px;
    height:20px;
}
.mt-20{
    margin-bottom:30px
}
.details_left{
    width:100%;
    /* display: flex; */
}
.view-details{
    width:100%;
    display: flex;
}
.view-details-one{
    width:25%;
}
.view-details-two{
    width:73%;
    margin-left:2%;
}
.payment-histroy .view-details-one{
    width:60%;
}
.payment-histroy .view-details-one span{
    float: right;
}
.payment-histroy .view-details-two{
    width:38%;
    margin-left:2%;
}
.details_left p{
    width: 100%;
    float: right;
    color: #000;
}
.details_left p span{
    float: right;
}
.details_right{
    width:73%;
    margin-left:2%
}
/* .car-listing-row table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
} */
.student_detail-full{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.student_detail-full .details_left {
    width: 40%;
}
.student_detail-full .details_right {
    width: 58%;
    margin-left:2%
}
.button{
    border:2px solid #3E3E3C;
}
.button:hover{
    color:#3E3E3C;
    background-color: #fff;
    border:2px solid #3E3E3C;
}
/* ---- Sidebar Menu ---- */
.sidebar-menu {
    background: #000;
    position: fixed;
    top: 55px;
    height: calc(100% - 55px);
    width: 260px;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 1px 0 20px #000017;
    z-index: 21;
}
.sidebar-menu .main-menu {padding: 30px 0px; height:100%;}
.menulist {
    list-style: none; 
    padding: 0; 
    margin: 0;
}
.menulist > li {
    margin: 10px 0px;
    margin-left: -20%;
    position: relative;
    animation: slidein 500ms forwards;
}
.menulist > li > a {
    padding: 18px 15px 18px 60px;
    position: relative;
    display: block;
    color: #fff;
    white-space: nowrap;
    font-size: 20px;
    font-weight: 400;
}
.menulist > li > a:hover, .menulist > li.active > a {
    background: #041629;
}
.sidebar-menu.menu-close .menulist > li > a {
    padding: 18px 15px 18px 50px;
}
.menulist > li > a > i {position: absolute; left: 15px; top: 0px; bottom: 0; margin: auto 0;}

.sidebar-menu .submenu.dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    width: auto;
    padding: 10px 0px;
    margin: -1px;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 0px 15px 15px 0px;
}

.sidebar-menu .submenu a {
    color: #fff; 
    display: block;
    padding: 10px 15px;
}
.sidebar-menu.menu-close .submenu {
    position: absolute !important;
    border: 4px;
    left: 30px !important;
    top: 0 !important;
    transform: none !important;
    border-radius: 4px;
}
.sidebar-menu.menu-close .submenu:before {
    content: "";
    border: 10px solid #f8931e;
    border-color: transparent #f8931e transparent transparent;
    position: absolute;
    left: -20px;
    top: 7px;
}
.sidebar-menu .submenu a:hover {
    background: #041629; color: #fff;
}
.menu-close.sidebar-menu {width: 75px;}

.menu-closed .sidebar-menu{width: 75px;}

.menu-close .main-menu.menu-fixed{width: 75px;}

.menu-close .navbar-header{width: 75px;}

.menu-close .dropdown-toggle::after {
    display: none;
}
.menu-close.sidebar-menu  + .content{
    margin-left: 75px;
}
.menulist > li > a > span {
    display: inline-block;
    opacity: 1;
    transform: translateX(0px);    
    visibility: visible;
}

.menu-close.sidebar-menu .menulist li a span {    
    visibility: hidden;
    opacity: 0; 
    transform: translateX(60px);
}


    @-webkit-keyframes slidein {
        from {
            margin-left: -10%;
        }

        to {
            margin-left: 0%;
        }
    }

    @-moz-keyframes slidein {
        from {
            margin-left: -10%;
        }

        to {
            margin-left: 0%;
        }
    }
     @-o-keyframes slidein {
        from {
            margin-left: -10%;
        }

        to {
            margin-left: 0%;
        }
    }
     @keyframes slidein {
        from {
            margin-left: -10%;
        }

        to {
            margin-left: 0%;
        }
    }
    
.menulist > li:nth-child(1){animation-delay: 0.40s; }
.menulist > li:nth-child(2){animation-delay: 0.48s; }
.menulist > li:nth-child(3){animation-delay: 0.56s; }
.menulist > li:nth-child(4){animation-delay: 0.64s; }
.menulist > li:nth-child(5){animation-delay: 0.72s; }
.menulist > li:nth-child(6){animation-delay: 0.80s; }
.menulist > li:nth-child(7){animation-delay: 0.88s; }



.prod-list-pay  { 
    padding: 12px 20px;
    border: 1px solid #00005e;
    margin: 0 0 15px;
    width: fit-content;
    background: #fff;
    border-radius: 0px;
    height: 48px;
    font-size: 16px;
    color: #000;
    font-family: Goldman;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0px;
}


/* ---- Content Area ---- */ 

.content {
    padding: 0;
    position: relative;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-left: 350px;
    overflow-x: hidden;
    margin-top: 55px;
}
.form-wrapper {padding: 60px 80px;}
.form-wrapper.leaa-admin-head.card {padding: 60px 50px;}
.form-row { display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 60px;}
.form-left, .form-right {width: 46%;}
.dashboard-wrap .form-left{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.dashboard-wrap .card {
    width: 31%;
}
.dashboard-wrap .card:nth-child(2), .dashboard-wrap .card:nth-child(3), .dashboard-wrap .card:nth-child(5), .dashboard-wrap .card:nth-child(6), .dashboard-wrap .card:nth-child(8){margin-left: 2%;}

.content-wrapper .form-left, .content-wrapper .form-right  {z-index: 2;}
.form-group {margin: 0 0 10px;}
.form-group label {margin-bottom: 0px; display: inline-block; color: #000;cursor: pointer;padding: 24px 0px;    font-size: 24px;font-weight: 600;letter-spacing: 0.8px;}
.form-group textarea.form-control {height: 200px; line-height: 30px; /*resize: none;*/ }
.title{padding-bottom: 15px; text-align: center; position: relative;}
.title h4, .title h3{ font-size:30px; font-weight: 500; margin: 0px 0px 15px 0px;}
.btn-group.export {text-align: center;}
.add_button .add {border-bottom: 1px solid #000; color: #000; line-height: 1;}
.listing-header .add_button a{font-size: 18px; font-weight: 600;color: #000;}
        .listing-header .add_button a:hover{color: #000;}
.status_sucess {
    left: 0; right: 0; top: 10%; text-align: center; background-color: #ffff;
    border-color: #d6e9c6; padding: 45px 15px; margin-bottom: 20px; border: 1px solid transparent;
    border-radius: 4px; position: absolute;  margin: auto;  width: 55%; min-height: 120px; z-index: 9999;
    -webkit-box-shadow: 8px 8px 41px 2px rgba(214,214,214,1);
    -moz-box-shadow: 8px 8px 41px 2px rgba(214,214,214,1);
    box-shadow: 8px 8px 41px 2px rgba(214,214,214,1);
}
.status_sucess h3{
    margin: 0px 0px 0px 0px; padding: 0px; font-weight: 600; line-height: 1.2;
    color: #000; font-size: 28px;
}
p.error-txt {text-align: left; margin:0;}
.status_sucess .close {
    position: absolute; right: 10px; font-size: 20px; font-weight: 600; top: 10px; padding: 2px 4px; cursor: pointer;
}
.custom-scroll {height: 150px; overflow: auto;}
.download {text-align: center; margin-top: 10px;}
.download a {font-size: 18px; font-weight: 600;}

.form-row.import-user-record {justify-content: center;}
.car-listing-wrap {padding: 70px 90px;}
table {border-collapse: collapse;  width: 100%;} 
table td, table th {border: 1px solid #ddd;}  
table tr:nth-child(even){background-color: #f2f2f2;}
table tr:hover {background-color: #ddd;}  
table th {padding-top: 12px;  padding-bottom: 12px; background-color: #fff; color: #000;vertical-align: bottom;
    border-top: 1px solid #e3ebf3;
    border-bottom: 2px solid #e3ebf3;font-weight: bold;}

.listing-header {display: flex; justify-content: space-between;}
.choose-file, .form-group.vehicles-select{ margin-bottom: 25px;}
.change-password-wrap.account-wrapper {
    width: 600px; padding: 70px;  border-radius: 6px; background-color: #fff;
    box-shadow: none; margin: auto; position: relative; top: unset; left: unset; transform: none;
}
.form-group.eye-pad {position: relative;}
.form-group.eye-pad span.shown {position: absolute; top: 48px; right: 15px;}
.back.list-page {
    position: absolute; top: 10px; left: 0; border-bottom: 1px solid #000094;
    line-height: 1; font-size: 18px;
}
.errorspan {color: #000;font-size: 22px;}
.actiongroup{ text-align: center;}

ul.carimagelisting {display: flex; flex-wrap: wrap;}
ul.carimagelisting li.thumb {
    display: flex;
    align-items: center;
    width: 23%;
    margin: 1% 1%;
    border: 1px solid #e2dddd;
    position: relative;
    padding: 5px;
}
ul.carimagelisting .thumb input{
    margin: 0 5px 0 0;
}
ul.carimagelisting .thumb img {
    height: 60px;
}
ul.carimagelisting .thumb a {
    position: absolute;
    right: 0;
    top: 0;
    background: #000030;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 12px;
}
.carimagelisting .thumb input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}
.carimagelisting .thumb span {
    position: relative;
    padding: 0 0 0 35px;
    pointer-events: none;
    display: block;
}
.carimagelisting .thumb span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 22px;
    height: 22px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 20px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.carimagelisting .thumb input[type=radio]:checked + span:before {    
    background-image: url(../images/check.svg); 
    background-color: #3E3E3C;
    background-position: 4px 6px;
    background-size: 12px;
    z-index: 1;
    border: 1px solid #3E3E3C;
    background-repeat: no-repeat !important;
    height: 22px;
    width: 22px;
    display: inline-block;
}
.actiongroup a {display: inline-block; margin: 0px 2px; font-size: 18px;}
.drivers-deatils .form-group {margin: 0 0 30px; display: flex; align-items: center;}
.drivers-deatils .profile-photo img {height: 70px; margin-left: 15px;}
.drivers-deatils .driver-icon img {height: 40px; margin-left: 10px;}
.listing-header .add_button {padding-top: 10px;}
.custom-scroll-wrap {height: calc(100% - 0px) !important;}
.site-bg-clr {display: flex; align-items: center;}
.site-bg-clr label {margin-bottom: 0px; margin-right: 10px;}
table input[type="text"], input[type="number"]{padding: 10px 20px; margin: 0 0 0px; height: 40px;}


.search-inner {position: relative; height: 42px;}
.search-inner input[type="text"]{margin: 0 0 0px; height: 100%; padding: 10px 45px 10px 20px; font-size: 18px;}
.search-inner button {
    margin: 0 0 0px; background-image: url(../images/search1-white.svg); background-size: contain;
    background-color: #3E3E3C; background-repeat: no-repeat; min-width: auto; font-size: 0;
    background-size: 20px 20px; height: 100%; position: absolute; background-position: center;
    right: 0; padding: 10px 22px;
}
.pagination {display: inline-block; padding-left: 0; margin: 20px 0; border-radius: 4px;}
.pagination li {display: inline;}
.pagination li a {
    position: relative; float: left; padding: 6px 12px; line-height: 1.4; text-decoration: none; color: #000;
    background-color: #fff; border: 1px solid #ddd; margin-left: -2px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 3; color: #fff; background-color: #3E3E3C; border-color: #3E3E3C; cursor: default;
}
.text-center {text-align: center;}
.map-wrapper {height: 600px; position: relative;}
 #map {position: absolute; top: 0; bottom: 0; width: 100%;}
.ongoing-wrapper.form-row {padding: 30px 30px 20px; margin-bottom: 40px; align-items: center; border: 1px solid #d8d8d8;}
.ongoing-wrapper.form-row .form-left {border-right: 1px solid #d8d8d8;}
.neardrive-wrapper {max-height: 300px; overflow-y: auto;}
.neardrive-wrapper h3 {font-size: 22px; font-weight: 700; margin: 0px 0px 15px 0px;}
.neardrive-wrapper li {line-height: 1.4; margin-bottom: 5px;}

.confirm-action {
    opacity: 0;
    visibility: hidden;
    max-width: 700px;
    height: 170px;
    border: 1px solid #fff;
    background: #fff;
    padding: 25px 35px 35px 35px;
    margin-bottom: 25px;
    text-align: center;
    position: fixed;
    z-index: 9;
    left: 0;
    margin: auto;
    right: 0;
    bottom: 0;
    top: 0;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 14%);
    transition: 0.6s all ease;
}
.confirm-action.show {opacity: 1; visibility: visible; animation: slideInFromRight 600ms forwards;}
.confirm-action p {font-size: 24px;}
.confirm-action .btn {
    margin-top: 10px;
    margin-right: 20px;
    width: 100px;
    font-size: 26px;
    border-radius: 0px !important;
    height: 52px !important;
    line-height: 48px !important;
    border: 2px solid #3E3E3C !important;
    font-weight: 700 !important;
    color: #000 !important;
    padding: 0px 10px !important;
    display: inline-block;
    text-align: center;
    transition: 0.3s all ease;
    background: #fff;
    position: relative;
}
.confirm-action .btn:hover {color: #fff !important; background: #3E3E3C !important;}
.overflow {overflow: auto;}
.student-list-row{margin: 20px 0px; justify-content: center;}
.student-list-row .form-group {width:31%; margin: 0 10px;}

.mail-template-sec .form-left.mail-template-inner {width: 100%;}
.dashboard-wrap .form-row.first {margin-top: 60px;}
.dashboard-box {
    border-radius: .25rem; box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%); display: block;
    position: relative; height: 180px; padding: 25px 25px; color: #fff;
}
.dashboard-box h3.count {
    font-size: 50px; font-weight: 600; margin: 0 0 15px; padding: 0; white-space: nowrap; color: #fff;
}
.dashboard-box p {margin: 0 0 0px; font-size: 30px;}
.form-group .react-datepicker-wrapper {width: 100%;}

.search-total{
    display: flex;
}
.search-total .select-2 select{
    height:42px;
    padding: 9px 20px
}
.search-total .select-1 select{
    height:42px;
    padding: 9px 20px
}  
.select-2{
    margin-right:10px
}
.select-1{
    margin-right:10px
}
.default-font .css-1okebmr-indicatorSeparator{
    display: none;
}
.select-1 select{
   
    padding: 6px 20px;
    height:42px;
}
.select-2 select{
    height:48px;
    padding: 6px 20px;
    height:42px;
}
.table-avrech table{
    display: table;
    overflow-x: auto;
    white-space: nowrap;
}

.search-list-date {
    display: flex; align-items: center; margin-bottom: 20px;
}
.search-list-date form {
    display: flex;
}
.search-list-date .start-date, .search-list-date .end-date {
    margin-right: 10px;
}
.start-date input, .end-date input{
    margin: 0 0 0px !important;
}
.search-list-date button {
    margin: 0px 10px 0!important;
    min-width: 60px;
    width: 60px;
    padding: 10px 15px;
}

/*Perumal 01-03-2022*/
.submit-button{display: flex;justify-content: space-between;align-items: baseline;}
.details-view span h3{margin-bottom: 10px}
.details-view-full{display: flex;}
.details-view-right{margin-left: 5px;}
.details-view-left{font-weight: 600}
.form-build-submit-btn{justify-content: space-around;align-items: center;}
.form-entries{width: 100%}
.entries-details-view span h3{font-size: 32px; margin-bottom: 15px}
.entries-details-view .details-view-left {width: 25%;}
.entries-details-view .details-view-right {width: 73%;margin-left: 2%;}
.form-entries-main .view-details-one p span {float: right;}
.details-view-left span {float: right;}
.car-listing-row{overflow-x: auto;}


.input_search{
    display: flex;
    justify-content: space-between !important;
}
.search_input, .search_select{
    width:100%;
    margin-left: 10px;
    margin-right: 10px;
}
.search_btn{
    margin-left: 55px;
}

.search_select{
    font-size:21px;
}

.search_input input::placeholder{
    font-size: 21px !important;
}

.css-1pahdxg-control{
    border-color: #000!important;
    box-shadow: 0 0 0 1px #000!important;
    border-width: 0px!important;
    height: 68px!important;
    min-height: 68px!important;
    padding: 17px 25px!important;
    border-radius: 0px!important;
}

.css-1pahdxg-control:hover{
    border-color: #000!important;
    height: 68px;

}

.svgspan {
    display: flex;
    align-items: center;
}

.svgspan a:first-child {
    margin-left: 10px;
}

.close-support {
    position: absolute;
    top: 10px;
    font-size: 28px;
    right: 15px;
    font-weight: 600;
    cursor: pointer;
}

/* / Scroll bar css / */

/* / width / */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* / Handle / */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.main-menu.menu-dark .navigation-background:after{
    background-color: #000 !important;
}


@media(min-width:1400px){
    body{ font-size:28px;}
    .header {height: 85px;}
    .myaccount-inner .user-name {font-size: 26px; }
    .logo-box {height: 75px; width: 340px;}
    .logo-box a{font-size: 32px; line-height: 45px;}    
    .header-cnt {height: 75px; width: calc(100% - 340px);}
    .search-box .form-control {padding: 12px 22px 12px 62px; height: 60px; font-size: 28px;}
    .search-icon {top: 2px; left: 2px; padding: 12px 10px 2px;}
    .sidebar-menu {top: 75px; height: calc(100% - 70px); /*width: 340px;*/}
    .icon {width: 40px; height: 40px;}
   
    .header-cnt .icon{height:33px; width:33px;}
    ul.user-menu {top: 65px; width: 360px; height: 240px;}    
    ul.user-menu li {padding: 8px 0px;}
    ul.user-menu li a {padding-left: 50px; font-size: 28px; }
    ul.user-menu .icon {top: 0px; }
    .menulist > li > a {padding: 23px 15px 23px 70px; font-size: 20px;}
    .content {margin-left: 340px; margin-top: 75px;} 
    .details_left p {
        width: 100%;
        font-size: 100%;
        float: right;
        color: #000;
    }
    .form-group.eye-pad span.shown{top: 90px}
    .form-group input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], textarea, select, .form-control{height: 68px;font-size: 100%;padding: 24px;line-height: 20px;}
    .form-group select{padding: 15px;}
    .form-group select:focus {
    color: #000;
    border-color: #000;
    outline: 0;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}
    /*--Form Wrapper--*/
    .form-wrapper {padding: 80px 40px;}
    .form-wrapper.leaa-admin-head.card {padding: 60px 50px;}
    .form-wrapper .form-control {padding: 12px 25px; height: 68px; font-size: 28px;}
    .form-group .css-yk16xz-control {padding: 17px 25px;height: 68px;font-size: 100%;}
    .content-wrapper .form-control {padding: 12px 25px; height: 68px; font-size: 28px;}
    .content-wrapper .form-group input[type="file"] {/*padding: 12px 25px; height: 68px; font-size: 28px;*/width: 45px;height: 45px;}
    .content-wrapper .form-group input[type="file"]:after{font-size: 35px;}
    .form-group .css-g1d714-ValueContainer{height: 68px; font-size: 100%;}
    .title h4{ font-size:42px;font-weight: 500;}
    .title h3{ font-size:36px;font-weight: 500;}
    .car-listing-wrap {padding: 90px 120px;}
    .car-listing-wrap.dashboard-wrap{padding: 90px 240px;}
    a.back.list-page {font-size: 18px;}
    .listing-header .add_button a {font-size: 22px;}
    .search-inner {height: 42px;}
    .search-inner input[type="text"] {padding: 10px 50px 10px 20px; font-size: 21px;}
    .search-inner button {background-size: 24px 24px; padding: 10px 25px;}
    .pagination li a {padding: 6px 18px;margin-left: -2px;}
    .import-icon {
        width: 25px;
        height: 25px
    }
    .export-icon{
        width: 25px;
        height: 25px
    }
    .default-font .css-g1d714-ValueContainer{
        /*max-height:100%;*/
    }
    .default-font .css-tlfecz-indicatorContainer{
        color: hsl(0,0%,70%);
    }
    .default-font .css-6q0nyr-Svg{
        width:30px;
        height:30px;
        color: #000;
    }
    .select-1 select {
        padding: 10px 20px;
        height: 56px;
        width: 100%;
        font-size: 27px;
    }
    .select-2 select {
        padding: 10px 20px;
        height: 56px;
        width: 100%;
        font-size: 27px;
    }
    .table-avrech table{
        display: table;
        overflow-x: auto;
        white-space: nowrap;
    }
.car-listing-row .table td, .car-listing-row table th {
        padding: 20px;
        font-size: 20px;
        vertical-align: middle;
        text-align: left;
    }
    .car-listing-row table th {
        font-size: 24px;
    }
    .actiongroup a {
        display: inline-block;
        margin: 0px 2px;
        font-size: 25px;
    }

    .form-group .css-1uccc91-singleValue{top: 40%;}

    .css-2b097c-container .css-26l3qy-menu {background: #ffffff!important; color:#000000!important;}
    .css-2b097c-container .css-26l3qy-menu div{
        background: #ffffff!important; color:#000000!important;
        /* z-index: 999; */
    }
    .css-2b097c-container {
        position: relative; 
        /* z-index: 992; */
    }
    
  /* .domain-url .errorsettings_domain_title2  button {
    color: white;
    padding: 10px !important;
    min-width: 61px !important;
    height: 60px !important;
    line-height: 8px;
    background-color: rgb(5, 31, 58);
    border-radius: 0px 5px 5px 0px;
  }
  .domain-url .errorsettings_domain_title1 button{
    color: white;
    padding: 10px !important;
    min-width: 61px !important;
    height: 60px !important;
    line-height: 8px;
    background-color: rgb(5, 31, 58);
    border-radius: 0px 5px 5px 0px;
  }
  .domain-url button i{
      font-size:35px;
  } */
}   
@media(max-width:1199px){
    .form-wrapper, .car-listing-wrap {padding: 40px 30px;}
    .title h4, .title h3 {font-size: 26px;}
    .form-left, .form-right {width: 48%;}
    
}
@media(max-width:991px){

    .view-details-one{width: 50%}
    .view-details-two{width: 48%}
.builderpage-module-pagewrapper{
        padding: 60px 20px!important;
}
.search-list-date{align-items: flex-end;justify-content: center;}
.search-list-date .react-datepicker-wrapper{width: 100%}
.search-list-date form{align-items: flex-end;}
.submit-button{
    display: block!important;
    width: 100%

}

.builderpage-module-pagewrapper .submit-button{
    display: flex!important;
    width: 100%

}

.sortablecontainer-module-wrapper{
    margin-top: 10px!important
}
.submit-button button{
    width: 100%
}

.form-build-submit-btn button{
    width: auto;
    min-width: 100px;
}

.form-build-submit-btn{
    justify-content: space-between;
}

.btn {min-width: 140px; padding: 8px 24px; height: 42px;}
.header {height: 60px;}
.logo-box.mobi-close {display: none;}   
.mobi-logo{display:block;}  
.header-cnt {width: calc(100% - 0px);}

.sidebar-menu {top: 60px; height: calc(100% - 60px);}
.sidebar-menu .main-menu {padding: 15px 0px;}
.mobi-close.sidebar-menu {width: 75px;}
.mobi-close.sidebar-menu .menulist li a span {visibility: hidden; opacity: 0; transform: translateX(60px);} 
.mobi-close.sidebar-menu + .content{margin-left: 75px;}
.mobi-close.sidebar-menu .dropdown-toggle::after {display: none;}

.mobi-close.sidebar-menu .submenu {
    position: absolute !important; border: 4px; left: 30px !important; top: 0 !important; 
}
.mobi-close.sidebar-menu .submenu:before {
    content: ""; border: 10px solid #f8931e; border-color: transparent #f8931e transparent transparent;
    position: absolute; left: -20px; top: 7px;
}   
.mobi-close.menu-close.sidebar-menu {width: 240px;}
.mobi-close.menu-close.sidebar-menu .menulist li a span {    
    visibility: visible; opacity: 1; transform: translateX(0px);
}
.mobi-close.menu-close.sidebar-menu .submenu.show {
    position: static !important; left: 0 !important; top: 0 !important;
}
.mobi-close.menu-close.sidebar-menu .dropdown-toggle::after {display: inline-block;}
.mobi-close.menu-close.sidebar-menu .submenu:before {
    border-color: transparent transparent transparent transparent;
}
.dropdown-menu {font-size: 15px;}
.logo-box.active + .header-cnt {width: calc(100%);}
.menulist > li > a {font-size: 16px;}
.form-left, .form-right {
    width: 100%;
}
.product-subscription-form-left{
    width: 100%
}
}
@media(max-width:820px){    
.sortablecontainer-module-list{
    padding: 14px 14px 18px 30px!important;
}
.sortablecontainer-module-edit-button>button{
    font-size: 12px!important
}
.sortablecontainer-module-wrapper {
    margin-top: 50px!important;
}
.builderpage-module-pagewrapper{
    grid-column-gap: 30px!important;
}
.builderpage-module-pagewrapper .sortablecontainer-module-wrapper {
    margin-top: 0px!important;
}
.entries-details-view .details-view-left{
    width: 50%
}
.entries-details-view .details-view-right{
    width: 48%;
    margin-left: 2%;
}
.form-entries .view-details-one{
    width: 50%
}
.form-entries .view-details-two{
    width: 48%;
    margin-left: 2%;
}
}
@media(max-width:580px){  

.search-inner{
    height: 38px !important;
}

.builderpage-module-pagewrapper>form:nth-child(2){
    order: 2!important
}
.builderpage-module-pagewrapper>section:first-child{
    order: 1!important
}
.listing-header{display: block;}
.search-list{padding-bottom: 15px;}
.listing-header .add_button{padding-bottom: 15px}

}
@media(max-width:480px){        
.menu-icon.active .icon_menu1 {transform: translateY(8px) rotate(45deg) scale(1);}
.mobi-close.sidebar-menu {width: 55px;}
.menulist > li > a > i {left: 11px; top: 0px; width: 30px; height: 24px; line-height: 24px; font-size: 22px;}
.menulist > li > a {padding: 12px 15px 12px 50px;}
.sidebar-menu .submenu.show {margin: 0px 10px 0px 40px;}
.mobi-close.sidebar-menu + .content {margin-left: 55px;}
.sortablecontainer-module-edit-button>button{
    font-size: 12px!important;
    margin: 0 !important
}
.sortablecontainer-module-edit-button button:first-child {
    margin-right: 14px!important;
}
.search-list-date form{display: block;}
.search-list-date .start-date, .search-list-date .end-date{    margin-top: 10px;margin-bottom: 10px;}
}


@media(max-width:380px){
.sortablecontainer-module-list{
    padding: 14px 14px 18px 30px!important;
    font-size: 13px!important
}
.sortablecontainer-module-edit-button button:first-child {
    margin-right: 10px!important;
    
}
.sortablecontainer-module-edit-button>button{
    padding: 5px 5px!important
}
}

@media(max-width:340px){
.sortablecontainer-module-edit-button>button {
    font-size: 10px!important;
    margin: 0 !important;
}
}

.marker {
  background-image: url('../images/sq.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}


.form-group .css-1hwfws3{
    height: 48px; 
}


.choose-car {
    margin-bottom: 25px;
}
.choose-car span {
    border: 1px solid #c5c0be;
    display: block;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    background: #d3d0cf5e;
}
.cargallery-popup {
    width: 660px;
    height: 460px;
    background: #FFFFFF;
    position: absolute;
    left: 60px;
    top: 0px;
    z-index: 3;
    display: none;
    padding: 40px 20px 90px;
    border-radius: 1px;
    transition: all 0.65s ease;
    -webkit-transition: all 0.65s ease;
    overflow: hidden;
    box-shadow: 0px 24px 34px #0404052d;
    -webkit-box-shadow: 0px 24px 34px #0404052d;
    -ms-box-shadow: 0px 24px 34px #0404052D;
    transition-delay: 0.05s;
    -webkit-transition-delay: 0.05s;
    -webkit-border-radius: 1px;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
}
.cargallery-popup.active {display: block;}
.cargallery-popup-inner {height: 100%; margin-bottom: 20px;}
.cargallery-popup .car-listing {
    display: flex;
    flex-wrap: wrap;
    padding-right: 5px;
}
.cargallery-popup .asp-col {
    display: flex;
    align-items: center;
    width: 23%;
    margin: 1% 1%;
    padding:10px;
    border: 1px solid #e2dddd;
    position: relative;
}
.cargallery-popup .asp-col input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}
.cargallery-popup .asp-col label {
    display: block;
    text-align: center;
    width: 100%;
}
.cargallery-popup .asp-col img {
    height: 120px;
}
.cargallery-popup .asp-col label:before {
    content: "";
    background: transparent;
    position: absolute;
    width: 22px;
    height: 22px;
    border: 1px solid transparent;
    right: -1px;
    top: -1px;
    pointer-events: none;
    border-radius: 0%;
    -webkit-border-radius: 0%;
}
.cargallery-popup .asp-col input[type=checkbox]:checked + label:before {
    background-image: url(../images/check.svg);
    background-color: #3E3E3C;
    background-position: 4px 6px;
    background-size: 12px;
    z-index: 1;
    border: 1px solid #3E3E3C;
    background-repeat: no-repeat !important;
    height: 22px;
    width: 22px;
    display: inline-block;
}
.cargallery-popup .btn-group {
    text-align: center;
}
.cargallery-popup .btn {
    min-width: 80px;
    height: 44px;
    color: #fff;
    margin: 0px 0px 0px;
    padding: 10px 20px;
    font-size: 20px;
}
.cargallery-popup .btn:hover {
    background: #3E3E3C;
    color: #fff;
    border: 2px solid #3E3E3C;
}
.close-gallery {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 24px;
    height: 24px;
    padding: 2px;
    line-height: 20px;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 28px;    
    cursor: pointer;
}
.or{text-align: center;}


.builderpage-module-pagewrapper{
        margin: 0 auto 100px;
    max-width: 2000px;
    overflow: hidden;
    /*padding: 0 20px 100px;*/
        padding: 60px 80px;
    grid-column-gap: 60px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(15rem,1fr));
}

.builderpage-module-pagewrapper>section:first-child{
    /*margin-top: 50px;*/
    order: 2;
}

.builderpage-module-pagewrapper>form:nth-child(2) {
    order: 1;
    margin-top: 15px;
}

.builderpage-module-section h2{
/*    font-size: 1.1rem;
    font-weight: 400;
    line-height: 2;
    margin: 10px auto 20px;
    max-width: 600px;
    overflow: hidden;
    position: relative;
    text-align: center;
    margin-top: 40px;
    */
    font-size: 28px;
    font-weight: bold;
    margin: 0px 0px 15px 0px;
    text-align: center;
}

.submit-button h2{
    font-size: 22px;
    font-weight: 400;
    line-height: 2;
}

.sortablecontainer-module-wrapper{
    margin-top: 0px;
}

.sortablecontainer-module-wrapper>ul {
    margin-left: 0;
    padding-left: 0;
}

.sortablecontainer-module-list{
    background: var(--color-primary);
    border: 1px solid #00005e;
    border-radius: 4px;
    color: #000;
    cursor: move;
    list-style: none;
    margin-bottom: 10px;
    padding: 14px 14px 18px 50px;
    position: relative;
}

.sortablecontainer-module-edit-button{
    float: right;
}

.sortablecontainer-module-edit-button button:first-child {
    margin-right: 14px;
}

.sortablecontainer-module-edit-button>button {
    background: #3E3E3C;
    border: 1px solid transparent;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 8px 8px;
    position: relative;
    text-transform: uppercase;
    top: -2px;
    min-width: auto;
    height: auto;
}

.form-btn-container{
    position: absolute;
    display: flex;
    right: 50px;
    gap: 35px;
    top: 0px;
 }

 .form-btn-container a{
    position: relative;
 }

.sortablecontainer-module-delete-btn{
    float: right;
}

.sortablecontainer-module-delete-btn button{
     background: #3E3E3C;
    border: 1px solid transparent;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 8px 8px;
    position: relative;
    text-transform: uppercase;
    top: -2px;
    min-width: auto;
    height: auto;
}

.validation-check{
    display: block;
    line-height: 2;
    margin-bottom: 13px;
    margin-top: 20px;
    text-align: left;
}

.validation-check label{
    cursor: pointer;
}


.sortablecontainer-module-wrapper li i{
    fill: #fff;
    display: inline-block;
    left: 15px;
    position: absolute;
    top: 17px;
    width: 20px;
}

input[type="radio" i] {
    width: 21px;
    height: 21px;
    top: 0px;
    left: 0px;
    margin: -2px 20px 0px 20px;
    cursor: pointer;
}

input[type="radio" i]:first-child{
   margin: -2px 10px 0px 0px;
}

input[type='radio']:checked:after {
    content: '';
    width: 21px;
    height: 21px;
    border-radius: 50px;
    top: 0px;
    left: 0px;
    position: relative;
    background-color: #3E3E3C;
    display: inline-block;
    visibility: visible;
}

input[type="checkbox" i] {
    width: 23px;
    height: 23px;
    top: 0px;
    left: 0px;
    margin: -2px 10px 0px 10px;
    cursor: pointer;
}

input[type='checkbox']:checked:after {
    content: '';
    width: 23px;
    height: 23px;
    top: 0px;
    left: 0px;
    position: relative;
    background-color: #3E3E3C;
    display: inline-block;
    visibility: visible;
}

.form-group input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea, select, .form-control{
    font-size: 24px
}

.product-subscription .form-group input{
    /*width: 49%*/
}

.product-subscription .form-group{
    /*display: grid;*/
}

.product-subscription-list{
    
}

.product-subscription-form-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
}

.product-subscription-form-left{
    width: 46%
}

.product-subscription-form-left .form-group span{
    margin-bottom: 15px;
    display: inline-block;
    color: #000;
    cursor: pointer;
}

input[type="file"] {
    box-sizing: border-box;
    display: inline-block;
    font-size: 0px;
    margin: 0;
    height: 25px;
    padding: 9px;
    position: relative;
    overflow: hidden;
    width: 18px;
        top: -6px;
    margin-left: 5px;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
    z-index: 2;
    cursor: pointer;
}

input[type="file"]:hover{color: #3E3E3C;}

input[type="file"]:after {
    font-family: FontAwesome;
    content: "\f093";
    display: block;
    width: 100%;
    height: 100%;
    padding: 0px 0px;
    font-size: 18px;
    background-color: transparent !important;
    background-clip: padding-box;
    border: none;
    border-radius: 0px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 0 !important;
    z-index: 11;
    position: absolute;
    top: 0;
    color: #3E3E3C;
    left: 0;
}

input[type="file"]::-webkit-file-upload-button {  
   font:inherit; padding:0; position:relative; border:none; text-align:left; position:relative; z-index:100;
   -webkit-appearance:none;
}

.paymenthistorygrp a{margin:0 0 ;}


/*New Template 16-sep-2022*/

.flexbox-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}

.box-shadow-2 {
    -webkit-box-shadow: 0 10px 18px 0 rgb(62 57 107 / 20%);
    box-shadow: 0 10px 18px 0 rgb(62 57 107 / 20%);
}

.position-relative .form-control {
    padding-right: -webkit-calc(2.75rem + 2px);
    padding-right: -moz-calc(2.75rem + 2px);
    padding-right: calc(2.75rem + 2px);
}

.round {
    border-radius: 2.5rem!important;
}

.has-icon-left .form-control {
    padding-right: 1.5rem;
    padding-left: -webkit-calc(2.75rem + 2px);
    padding-left: -moz-calc(2.75rem + 2px);
    padding-left: calc(2.75rem + 2px);
}

.has-icon-left .form-control {
    padding-left: 2.5rem;
}

.form-control-position {
    line-height: 3.2rem;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
}

.has-icon-left .form-control-position {
    right: auto;
    left: 3px;
}

html body a {
    color: #6967ce;
}

.btn-bg-gradient-x-purple-blue {
    transition: .5s;
    background-image: -webkit-gradient(linear,left top,right top,from(#9f78ff),color-stop(50%,#32cafe),to(#9f78ff));
    background-image: -webkit-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-image: -moz-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-image: -o-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-image: linear-gradient(90deg,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-size: 200% auto;
}

.btn-bg-gradient-x-purple-blue.btn-glow {
/*    -webkit-box-shadow: 0 0 12px 0 #9f78ff;
    box-shadow: 0 0 12px 0 #9f78ff;*/
    width: 80%;
    border-radius: 1.5rem;
}

.btn-bg-gradient-x-purple-blue.active, .btn-bg-gradient-x-purple-blue:active, .btn-bg-gradient-x-purple-blue:hover{
    background-image: -webkit-gradient(linear,left top,right top,from(#9f78ff),color-stop(50%,#32cafe),to(#9f78ff))!important;
    background-image: -webkit-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-image: -moz-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-image: -o-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-image: linear-gradient(90deg,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-position: right center;
}

.main-title{
    margin-bottom: 20px;
}

.text-right{
    text-align: right;
}

.title-18{
    font-size: 18px;
}

.form-control::placeholder{
    font-size: 16px;
}

.account-forgot-psw{
    font-size: 20px;
}

.theme-btn{

}

.theme-btn.login_submit{
        transition: .5s;
        background: #3E3E3C;
/*    background-image: -webkit-gradient(linear,left top,right top,from(#9f78ff),color-stop(50%,#32cafe),to(#9f78ff));
    background-image: -webkit-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-image: -moz-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-image: -o-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-image: linear-gradient(90deg,#9f78ff 0,#32cafe 50%,#9f78ff 100%);
    background-size: 200% auto;
        -webkit-box-shadow: 0 0 12px 0 #9f78ff;
    box-shadow: 0 0 12px 0 #9f78ff;
    border-radius:1.5rem;*/
    width: 100%;
    margin-top: 20px;
}

.theme-btn:hover{
/*    background-image: -webkit-gradient(linear,left top,right top,from(#9f78ff),color-stop(50%,#32cafe),to(#9f78ff))!important;
    background-image: -webkit-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-image: -moz-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-image: -o-linear-gradient(left,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-image: linear-gradient(90deg,#9f78ff 0,#32cafe 50%,#9f78ff 100%)!important;
    background-position: right center;*/
            background: #3E3E3C;
}

.account-forgot-psw a, .account-backreset-wrap a{color: #000;font-size: 22px;}

.account-forgot-psw a:hover, .account-backreset-wrap a:hover{color: #000;}
.mb-30{
    margin-bottom: 30px;
}

body.body-theme{
    background: url(../images/body-theme.jpg) center center no-repeat fixed;
    -webkit-background-size: cover;
    background-size: cover;
}


.main-menu.menu-dark .navigation-background:after {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: .88;
    background: #2c303b;
}

.main-menu .main-menu-content {
    position: relative;
    clear: both;
    height: -webkit-calc(100% - 5rem);
    height: -moz-calc(100% - 5rem);
    height: calc(100% - 5rem);
}

.ps {
    overflow: hidden!important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}

.main-menu ul.navigation-main {
    overflow-x: hidden;
}

.main-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.navigation {
    font-weight: 700;
    overflow-y: hidden;
    padding-bottom: 20px;
    letter-spacing: .2px;
}

.main-menu.menu-dark .navigation>li {
    line-height: 2.5rem;
    padding: 0;
}

.navigation li {
    position: relative;
    white-space: nowrap;
}

.main-menu.menu-dark .navigation li a {
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    color: #fff;
}

.navigation li a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-menu a, .select2-container--classic:focus, .select2-container--default:focus {
    outline: 0;
}

.main-menu.menu-dark .navigation>li>a {
    padding: 12px 36px 12px 18px;
}

.main-menu.menu-dark .navigation li a span {
    display: inline-block;
}

.main-menu .navigation > li > a > span {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: -o-transform 0.3s ease;
    -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease;
        animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.main-menu.menu-dark .navigation > li > a > i {
    display: inline-block;
    margin-right: 20px;
    background: #3E3E3C;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px black;
    box-shadow: 0px 0px 10px black;
        float: left;
        font-family: 'Goldman';
        font-weight: 500;
}

.main-menu.menu-dark .navigation > li > a.active{
    color: #fa626b;
}

.main-menu.menu-dark .navigation>li>a i {
    position: relative;
}

.main-menu .navigation > li > a > i:before {
    font-size: 1.5rem;
}

.main-menu .navigation > li > a > i:hover:before{
    color: #fff;
}

.main-menu.menu-dark .navigation > li > a:hover i{
    color: #fff;
}

.ft-home:before {
    content: "\e965";
}

.ft-layers:before {
    content: "\e96a";
}

.ft-monitor:before {
    content: "\e983";
}

.ft-zap:before {
    content: "\e9e7";
}

.ft-aperture:before {
    content: "\e90a";
}

.ft-grid:before {
    content: "\e961";
}



.main-menu.menu-dark .navigation>li:hover>a {
    color: #fa626b;
}

 .main-menu.menu-dark {
    color: #dcdcdc;
    background: #2C303B; }
    .main-menu.menu-dark .main-menu-header {
      padding: 20px; }
      .main-menu.menu-dark .main-menu-header .menu-search {
        background: #16181e;
        padding: 0.5rem 1rem;
        color: #c3c3c3; }
        .main-menu.menu-dark .main-menu-header .menu-search:focus {
          border-color: #0b0c0f; }
    .main-menu.menu-dark .navigation .navigation-header {
      color: #dcdcdc;
      padding: 30px 20px 8px 20px; }
      .main-menu.menu-dark .navigation .navigation-header span {
        font-weight: 500; }
    .main-menu.menu-dark .navigation li a {
      color: #dcdcdc;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      transition: all 0.2s ease; }
      .main-menu.menu-dark .navigation li a span {
        display: inline-block; }
      .main-menu.menu-dark .navigation li a span.menu-sub-title {
        color: white; }
    .main-menu.menu-dark .navigation li.hover > a, .main-menu.menu-dark .navigation li.open > a, .main-menu.menu-dark .navigation li.active > a {
      color: #FFFFFF; }
    .main-menu.menu-dark .navigation > li {
      padding: 0;
      line-height: 2.5rem; }
      .main-menu.menu-dark .navigation > li:last-child{
        margin-bottom: 60px;
      }
      .main-menu.menu-dark .navigation > li > a {
        padding: 12px 36px 12px 18px;        font-family: 'Goldman';
        font-weight: 500;font-size: 21px; }
        .main-menu.menu-dark .navigation > li > a .label {
          margin-top: 4px;
          margin-right: 5px; }
        .main-menu.menu-dark .navigation > li > a i {
          position: relative; }
         .main-menu.menu-dark .navigation > li > a i:hover{
            -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
      box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
         } 
      .main-menu.menu-dark .navigation > li.open > a {
        color: #dcdcdc;
        color: #fa626b; }
      .main-menu.menu-dark .navigation > li.open .hover > a {
        -webkit-transform: translateX(-4px);
        -moz-transform: translateX(-4px);
        -ms-transform: translateX(-4px);
        -o-transform: translateX(-4px);
        transform: translateX(-4px); }
        .main-menu.menu-dark .navigation > li.open .hover > a:before {
          content: "";
          display: block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: #fa626b;
          border-color: #fa626b;
          position: absolute;
          left: 75px;
          top: 50%;
          -webkit-transform: translate(-10px, -50%);
          -moz-transform: translate(-10px, -50%);
          -ms-transform: translate(-10px, -50%);
          -o-transform: translate(-10px, -50%);
          transform: translate(-10px, -50%);
          opacity: 1;
          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          transition: all 0.2s ease; }
      .main-menu.menu-dark .navigation > li:not(.open) > ul {
        display: none; }
      .main-menu.menu-dark .navigation > li.hover > a, .main-menu.menu-dark .navigation > li:hover > a, .main-menu.menu-dark .navigation > li.active > a {
        color: #3E3E3C; }
        .main-menu.menu-dark .navigation > li.hover > a > span, .main-menu.menu-dark .navigation > li:hover > a > span, .main-menu.menu-dark .navigation > li.active > a > span {
          -webkit-transform: translateX(4px);
          -moz-transform: translateX(4px);
          -ms-transform: translateX(4px);
          -o-transform: translateX(4px);
          transform: translateX(4px); }
        .main-menu.menu-dark .navigation > li.hover > a.mm-next, .main-menu.menu-dark .navigation > li:hover > a.mm-next, .main-menu.menu-dark .navigation > li.active > a.mm-next {
          background-color: inherit; }
      .main-menu.menu-dark .navigation > li.active > a {
        color: #dcdcdc;
        font-weight: 700;
        background: #3E3E3C; }
      /*.main-menu.menu-dark .navigation > li .active {
        background: #1f2229; }*/
        .main-menu.menu-dark .navigation > li .active > a {
          color: #f6f6f6;
          font-weight: 700;
          color: #fa626b; }
        .main-menu.menu-dark .navigation > li .active .hover > a {
          background: #23262f; }
      .main-menu.menu-dark .navigation > li ul {
        padding: 0;
        margin: 0;
        font-size: 0.94rem; }
        .main-menu.menu-dark .navigation > li ul li {
          color: #dcdcdc;
          background: transparent; }
          .main-menu.menu-dark .navigation > li ul li > a {
            padding: 10px 18px 10px 90px; }
        .main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul {
          display: none; }
        .main-menu.menu-dark .navigation > li ul .open > a {
          color: #dcdcdc; }
        .main-menu.menu-dark .navigation > li ul .open > ul {
          display: block; }
          .main-menu.menu-dark .navigation > li ul .open > ul li > a {
            padding: 10px 18px 10px 100px; }
          .main-menu.menu-dark .navigation > li ul .open > ul .open > ul {
            display: block; }
            .main-menu.menu-dark .navigation > li ul .open > ul .open > ul li > a {
              padding: 10px 18px 10px 110px; }
        .main-menu.menu-dark .navigation > li ul .hover > a, .main-menu.menu-dark .navigation > li ul:hover > a {
          color: #e6e6e6; }
        .main-menu.menu-dark .navigation > li ul .active {
          background: transparent; }
          .main-menu.menu-dark .navigation > li ul .active > a {
            color: #fa626b;
            font-weight: 700; }
            .main-menu.menu-dark .navigation > li ul .active > a:before {
              content: "";
              display: block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: #fa626b;
              border-color: #fa626b;
              position: absolute;
              left: 85px;
              top: 50%;
              -webkit-transform: translate(-10px, -50%);
              -moz-transform: translate(-10px, -50%);
              -ms-transform: translate(-10px, -50%);
              -o-transform: translate(-10px, -50%);
              transform: translate(-10px, -50%);
              opacity: 1;
              -webkit-transition: all 0.2s ease;
              -o-transition: all 0.2s ease;
              -moz-transition: all 0.2s ease;
              transition: all 0.2s ease; }
          .main-menu.menu-dark .navigation > li ul .active .hover > a {
            background-color: transparent; }
    .main-menu.menu-dark ul.menu-popout {
      background: #2C303B; }
      .main-menu.menu-dark ul.menu-popout li a {
        color: #dcdcdc; }
        .main-menu.menu-dark ul.menu-popout li a span.menu-sub-title {
          color: white; }
      .main-menu.menu-dark ul.menu-popout li.hover > a:before, .main-menu.menu-dark ul.menu-popout li:hover > a:before, .main-menu.menu-dark ul.menu-popout li.open > a:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #fa626b;
        border-color: #fa626b;
        position: absolute;
        left: 14px;
        top: 50%;
        -webkit-transform: translate(-10px, -50%);
        -moz-transform: translate(-10px, -50%);
        -ms-transform: translate(-10px, -50%);
        -o-transform: translate(-10px, -50%);
        transform: translate(-10px, -50%);
        opacity: 1;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease; }
      .main-menu.menu-dark ul.menu-popout .has-sub:not(.open) > ul {
        display: none; }
      .main-menu.menu-dark ul.menu-popout .open > a {
        color: #dcdcdc; }
      .main-menu.menu-dark ul.menu-popout .open > ul {
        display: block; }
        .main-menu.menu-dark ul.menu-popout .open > ul .open {
          background: #181b21; }
          .main-menu.menu-dark ul.menu-popout .open > ul .open > ul {
            display: block;
            background: #181b21; }
      .main-menu.menu-dark ul.menu-popout .hover > a, .main-menu.menu-dark ul.menu-popout:hover > a {
        color: #e6e6e6; }
      .main-menu.menu-dark ul.menu-popout .active > a {
        color: #f6f6f6; }
        .main-menu.menu-dark ul.menu-popout .active > a:before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #fa626b;
          border-color: #fa626b;
          position: absolute;
          left: 14px;
          top: 50%;
          -webkit-transform: translate(-10px, -50%);
          -moz-transform: translate(-10px, -50%);
          -ms-transform: translate(-10px, -50%);
          -o-transform: translate(-10px, -50%);
          transform: translate(-10px, -50%);
          opacity: 1;
          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          transition: all 0.2s ease; }
      .main-menu.menu-dark ul.menu-popout .active .hover > a,
      .main-menu.menu-dark ul.menu-popout .active :hover > a {
        background-color: transparent; }
    .main-menu.menu-dark .brand-text, .main-menu.menu-dark .toggle-icon {
      color: #FFFFFF; margin: 0px 0px 0px 0px;}

    .main-menu.menu-dark .navigation-background:after {
      background: #5e6c45;
      content: ' ';
      opacity: .88;
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%; }
  .main-menu.menu-fixed {
    position: fixed;
    height: 100%;
    /* fallback if needed */
    top: 0;
    z-index: 1051;width: 375px; }
    .main-menu.menu-fixed .main-menu-footer {
      position: fixed; }

/*.menu-collapsed .sidebar-menu{width: 75px;}*/

  .main-menu.menu-static {
    margin-top: -5rem; }
  .main-menu.menu-shadow {
    -webkit-box-shadow: 1px 0px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0px 30px rgba(0, 0, 0, 0.1); }
  .main-menu.menu-border {
    border-right: 1px solid #E4E7ED; }
  .main-menu.menu-native-scroll .main-menu-content {
    overflow-y: scroll; }
  .main-menu.menu-bordered.menu-light .navigation > li {
    border-top: 1px solid #E4E7ED; }
  .main-menu.menu-bordered.menu-dark .navigation > li {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .main-menu .navigation-background {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center; }
  .main-menu .main-menu-content {
    height: -webkit-calc(100% - 5rem);
    height: -moz-calc(100% - 5rem);
    height: calc(100% - 5rem);
    position: relative;
    clear: both; }
  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .main-menu ul.navigation-main {
      overflow-x: hidden; }
  .main-menu a {
    outline: none; }
    .main-menu a:hover, .main-menu a:focus {
      text-decoration: none; }
  .main-menu .badge {
    padding: 0.5em 0.6em 0.4em; }

.navigation {
  font-size: 1rem;
  letter-spacing: 0.2px;
  font-family: "Comfortaa", cursive, "Times New Roman", Times, serif;
  font-weight: 700;
  overflow-y: hidden;
  padding-bottom: 20px; }
  .navigation .navigation-header {
    font-family: "Comfortaa", cursive, "Times New Roman", Times, serif;
    font-weight: 400;
    line-height: 1.2;
    padding: 12px 22px;
    font-size: 1rem; }
  .navigation li {
    position: relative;
    white-space: nowrap; }
    .navigation li a {
      display: block;
      /* white-space: nowrap; */
      text-overflow: ellipsis;
      overflow: hidden; }
    .navigation li .is-hidden {
      /* transition: all 250ms ease-in;
            transform: scale(0);
            opacity: 0; */ }
    .navigation li .is-shown {
      /* transition: all 250ms ease-in;
            transform: scale(1);
            opacity: 1; */ }
    .navigation li.disabled a {
      cursor: not-allowed; }

.menu-popout li.disabled a {
  cursor: not-allowed; }

.dropdown-notification .notification-text {
  margin-bottom: 0.5rem; }

.dropdown-notification .notification-tag {
  position: relative;
  top: -28px;
  right: 20px; }

.main-menu-header .user-content {
  padding: 20px; }
  .main-menu-header .user-content .media {
    overflow: inherit; }
  .main-menu-header .user-content .media-body {
    vertical-align: bottom;
    opacity: 1;
    width: 150px;
    white-space: nowrap;
    -webkit-transition: all .3s ease .15s;
    -o-transition: all .3s ease .15s;
    -moz-transition: all .3s ease .15s;
    transition: all .3s ease .15s; }

.main-menu-footer {
  position: relative;
  overflow: hidden;
  bottom: 0;
  display: block;
  z-index: 1000;
  color: #c5c9d4;
  background-color: #16181e; }
  .main-menu-footer.footer-open {
    max-height: 500px;
    -webkit-transition: max-height 0.2s ease-in-out;
    -o-transition: max-height 0.2s ease-in-out;
    -moz-transition: max-height 0.2s ease-in-out;
    transition: max-height 0.2s ease-in-out; }
  .main-menu-footer.footer-close {
    max-height: 15px;
    -webkit-transition: max-height 0.2s ease-in-out;
    -o-transition: max-height 0.2s ease-in-out;
    -moz-transition: max-height 0.2s ease-in-out;
    transition: max-height 0.2s ease-in-out; }
  .main-menu-footer a {
    color: #a8aebe; }
    .main-menu-footer a:hover, .main-menu-footer a:focus {
      color: #FFFFFF; }
  .main-menu-footer .header {
    height: 19px;
    border-bottom: 1px solid #21242c; }
  .main-menu-footer .toggle {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  .main-menu-footer .content {
    padding: 0; }
    .main-menu-footer .content .actions > a {
      display: block;
      float: left;
      width: 33.33333%;
      padding: 1rem 0;
      color: #a8aebe;
      text-align: center;
      border-top: 1px solid #21242c;
      border-left: 1px solid #21242c; }
      .main-menu-footer .content .actions > a > span {
        font-size: 1.35rem; }
      .main-menu-footer .content .actions > a:hover, .main-menu-footer .content .actions > a:focus {
        color: #FFFFFF; }
.navbar-header {
    position: relative;
    z-index: 9;
    width: 290px;
    height: 100%;
    height: 5rem;
    padding: 0 0.85rem;
    transition: .3s ease all;
}        
.menu-expanded .navbar-header {
    width: 260px;
    z-index: 999;
}

.navbar-header ul.navbar-nav {
    overflow: hidden;
}

.main-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.position-relative {
    position: relative!important;
}

.mr-auto, .mx-auto {
    margin-right: auto!important;
}

.navbar-header .navbar-brand {
    margin-right: 0;
    padding: 17px 0;
        /*font-size: 1.25rem;*/
    line-height: inherit;
    display: initial;
        white-space: nowrap;
}

.navbar-header .navbar-brand img{    
		width: 210px;
    margin-left: 7px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    margin-top: 6px;
}

.navbar-header .navbar-brand{
    color: #fff;
    font-weight: bold;
    font-size: 40px;
    margin-left: 10px;
}

.menu-close .navbar-header .navbar-brand img{margin-left: 0px;}   

.account-title-warpper h1{
    font-size: 44px;
}

.navbar-header h3.brand-text{
	display: inline;
    padding-left: 13px;
    font-weight: 700;
    font-size: 26px;
    vertical-align: -webkit-baseline-middle;
}

.menu-close .navbar-header h3.brand-text{
    display: none;
}

.navbar-header .nav-toggle {
    position: absolute;
    right: 0;
}

nav .nav-item .nav-link {
    display: block;
}

.main-menu.menu-dark .toggle-icon {
    color: #fff;
}

.nav .nav-item i {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.ft-disc:before {
    content: "\e949";
}

.menu-flipped .main-menu {
    right: 0; } 

 .navbar-header .nav-toggle {
  position: absolute;
  right: 0; }

 .navbar .navbar-brand {
  white-space: nowrap; }

 .main-menu {
  -webkit-transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }
   .main-menu li a span {
    -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein; }

.menu-expanded .navbar-header {
  width: 260px;
  z-index: 999; }

.menu-expanded .navbar.navbar-brand-center .navbar-header {
  width: auto;
  z-index: 999; }

.menu-expanded .navbar .navbar-container {
  margin-left: 260px; }

.menu-expanded .navbar .navbar-brand .brand-text {
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein; }

.menu-expanded .main-menu {
  width: 260px; }
  .menu-expanded .main-menu .navigation .navigation-header .ft-minus {
    display: none; }
  .menu-expanded .main-menu .navigation > li > a > i {
    margin-right: 12px;
    float: left; }
    .menu-expanded .main-menu .navigation > li > a > i:before {
      font-size: 1.5rem; }
  .menu-expanded .main-menu .navigation > li > a > span {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: -o-transform 0.3s ease;
    -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease; }
    .menu-expanded .main-menu .navigation > li > a > span.badge {
      position: absolute;
      right: 20px;
      top: 18px; }
  .menu-expanded .main-menu .navigation li.has-sub > a:not(.mm-next):after {
    content: "\f112";
    font-family: 'LineAwesome';
    font-size: 0.85rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  .menu-expanded .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after {
    top: 8px; }
  .menu-expanded .main-menu .navigation li.open > a:not(.mm-next):after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  .menu-expanded .main-menu .main-menu-footer {
    width: 260px; }
  .menu-expanded .main-menu.menu-light .navigation > li > a > i {
    display: inline-block;
    margin-right: 20px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); }
  .menu-expanded .main-menu.menu-light .navigation .hover > a > i, .menu-expanded .main-menu.menu-light .navigation:hover > a > i,
  .menu-expanded .main-menu.menu-light .navigation .open > a > i {
    -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
    box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }
  .menu-expanded .main-menu.menu-dark .navigation > li > a > i {
    display: inline-block;
    margin-right: 20px;
    background: #2c303b;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px black;
    box-shadow: 0px 0px 10px black; }
  .menu-expanded .main-menu.menu-dark .navigation .hover > a > i, .menu-expanded .main-menu.menu-dark .navigation:hover > a > i,
  .menu-expanded .main-menu.menu-dark .navigation .open > a > i {
    -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
    box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }

.menu-expanded .content,
.menu-expanded .footer {
  margin-left: 260px; }

.menu-expanded .navbar-brand-center .content,
.menu-expanded .navbar-brand-center .footer {
  margin-left: 0; }

.menu-collapsed .navbar-header {
  /*width: 60px;*/
  /*z-index: 999;*/
   }
  .menu-collapsed .navbar-header .modern-nav-toggle {
    display: none; }
/*  .menu-collapsed .navbar-header.expanded {
    width: 260px;
    z-index: 1000; }*/
    .menu-collapsed .navbar-header.expanded .modern-nav-toggle {
      display: block; }
    .menu-collapsed .navbar-header.expanded .navbar-brand .brand-text {
      -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
      -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
      -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
      animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein; }
  .menu-collapsed .navbar-header .navbar-brand .brand-text {
    -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
    -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
    -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout; }

.menu-collapsed .navbar.navbar-brand-center .navbar-header {
  width: auto;
  z-index: 999; }

.menu-collapsed .navbar .navbar-brand {
  padding: 17px 0px; }

.menu-collapsed .navbar .navbar-container {
  margin-left: 60px; }

.menu-collapsed .main-menu {
  /*width: 60px;*/
  -webkit-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -moz-transition: 300ms ease all;
  transition: 300ms ease all;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .menu-collapsed .main-menu .main-menu-footer {
    width: 60px; }
  .menu-collapsed .main-menu .main-menu-footer,
  .menu-collapsed .main-menu .main-menu-header .media-body .media-heading,
  .menu-collapsed .main-menu .main-menu-header .media-body .text-muted,
  .menu-collapsed .main-menu .main-menu-header .media-right {
    display: none; }
  .menu-collapsed .main-menu .main-menu-header .media-body {
    opacity: 0; }
  .menu-collapsed .main-menu .user-content {
    padding: 20px 10px; }
  .menu-collapsed .main-menu .navigation {
    overflow: visible; }
    .menu-collapsed .main-menu .navigation > li.navigation-header .ft-minus {
      display: block;
      font-size: 1.2rem; }
    .menu-collapsed .main-menu .navigation > li.navigation-header span {
      display: none; }
    .menu-collapsed .main-menu .navigation > li > a {
      text-overflow: inherit; }
      .menu-collapsed .main-menu .navigation > li > a > span {
        -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
        -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
        -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
        animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout; }
        .menu-collapsed .main-menu .navigation > li > a > span.badge {
          float: none !important; }
      .menu-collapsed .main-menu .navigation > li > a i {
        margin-right: 12px;
        float: left; }
        .menu-collapsed .main-menu .navigation > li > a i:before {
          font-size: 1.5rem; }
  .menu-collapsed .main-menu .mTSWrapper {
    overflow: visible; }
  .menu-closed .main-menu.expanded {
    width: 75px; }

    .menu-collapsed .main-menu.expanded .navigation > li.navigation-header .ft-minus {
      display: none; }
    .menu-collapsed .main-menu.expanded .navigation > li.navigation-header span {
      display: block; }
    .menu-collapsed .main-menu.expanded .navigation > li > a > span {
      -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
      -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
      -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
      animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein; }
      .menu-collapsed .main-menu.expanded .navigation > li > a > span.badge {
        position: absolute;
        right: 20px; }
    .menu-collapsed .main-menu.expanded .navigation li.has-sub > a:not(.mm-next):after {
      content: "\f112";
      font-family: 'LineAwesome';
      font-size: 0.85rem;
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 14px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: -webkit-transform 0.2s ease-in-out; }
    .menu-collapsed .main-menu.expanded .navigation li.has-sub .has-sub > a:not(.mm-next):after {
      top: 8px; }
    .menu-collapsed .main-menu.expanded .navigation li.open > a:not(.mm-next):after {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg); }
    .menu-collapsed .main-menu.expanded.menu-light .navigation > li > a > i {
      display: inline-block;
      margin-right: 20px;
      background: #fff;
      width: 40px;
      height: 40px;
      border-radius: 12%;
      line-height: 40px;
      vertical-align: middle;
      text-align: center;
      -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); }
    .menu-collapsed .main-menu.expanded.menu-light .navigation .hover > a > i, .menu-collapsed .main-menu.expanded.menu-light .navigation:hover > a > i,
    .menu-collapsed .main-menu.expanded.menu-light .navigation .open > a > i {
      -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
      box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }
    .menu-collapsed .main-menu.expanded.menu-dark .navigation > li > a > i {
      display: inline-block;
      margin-right: 20px;
      background: #2c303b;
      width: 40px;
      height: 40px;
      border-radius: 12%;
      line-height: 40px;
      vertical-align: middle;
      text-align: center;
      -webkit-box-shadow: 0px 0px 10px black;
      box-shadow: 0px 0px 10px black; }
    .menu-collapsed .main-menu.expanded.menu-dark .navigation .hover > a > i, .menu-collapsed .main-menu.expanded.menu-dark .navigation:hover > a > i,
    .menu-collapsed .main-menu.expanded.menu-dark .navigation .open > a > i {
      -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
      box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }

.menu-collapsed .content,
.menu-collapsed .footer {
  margin-left: 60px; }

.menu-collapsed .navbar-brand-center .content,
.menu-collapsed .navbar-brand-center .footer {
  margin-left: 0; }

.menu-collapsed.menu-flipped .content {
  margin: 0;
  margin-right: 60px; }
.menu-expanded .navbar-header {
    width: 260px;
    z-index: 999;
}

.pull-up {
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.pull-up:hover {
    -webkit-transform: translateY(-4px) scale(1.02);
    -moz-transform: translateY(-4px) scale(1.02);
    -ms-transform: translateY(-4px) scale(1.02);
    -o-transform: translateY(-4px) scale(1.02);
    transform: translateY(-4px) scale(1.02);
    -webkit-box-shadow: 0px 14px 24px rgb(62 57 107 / 20%);
    box-shadow: 0px 14px 24px rgb(62 57 107 / 20%);
    z-index: 999;
}



.card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0.35rem;
}

.border-top-3 {
    border-top-width: 3px !important;
}



.card {
    margin-bottom: 1.875rem;
    border: none;
    -webkit-box-shadow: 0px 14px 24px rgb(0 0 0 / 20%);
    box-shadow: 0px 14px 24px rgb(0 0 0 / 20%);
}

.border-top-info {
    border-top: 1px solid #3e3e3c;
}

.border-top-info:before {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #3e3e3c;
  transform-origin: top right;
  transition: transform 0.25s ease-out;
}

.border-top-info:hover:before {
  transform: scaleX(1);
  transform-origin: top left;
}

.border-top-info-red {
    border-top: 1px solid #ff0000;
}

.border-top-info-red:before {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff0000;
  transform-origin: top right;
  transition: transform 0.25s ease-out;
}

.border-top-info-red:hover:before {
  transform: scaleX(1);
  transform-origin: top left;
}

.border-top-info-purple {
    border-top: 1px solid #c000ff;
}

.border-top-info-purple:before {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #c000ff;
  transform-origin: top right;
  transition: transform 0.25s ease-out;
}

.border-top-info-purple:hover:before {
  transform: scaleX(1);
  transform-origin: top left;
}

.border-top-info-yellow {
    border-top: 1px solid #ffc107;
}

.border-top-info-yellow:before {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffc107;
  transform-origin: top right;
  transition: transform 0.25s ease-out;
}

.border-top-info-yellow:hover:before {
  transform: scaleX(1);
  transform-origin: top left;
}

.rounded-0 {
    border-radius: 0 !important;
}

.card-header:first-child {
    border-radius: -webkit-calc(0.35rem - 1px) -webkit-calc(0.35rem - 1px) 0 0;
    border-radius: -moz-calc(0.35rem - 1px) -moz-calc(0.35rem - 1px) 0 0;
    border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
}

.card-header {
    border-bottom: none;
}

.card-header {
    padding: 1.5rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.card-header .card-title {
    margin-bottom: 0;
}

.card .card-title {
    font-weight: 700;
    letter-spacing: 0.05rem;
    font-size: 1.12rem;
}

.badge-info {
    background-color: #28afd0;
}

.badge {
    color: #FFFFFF;
    font-weight: 400;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge {
    display: inline-block;
    padding: 0.35em 0.4em;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.card-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem;
}

.text-bold-400 {
    font-weight: 400;
}

.font-large-1 {
    font-size: 2rem !important;
}

.ft-users:before {
    content: "\e9d8";
}

.ft-faq:before {
    content: "\e981";
    color: #fff;
}

.ft-faq-category:before {
    content: "\e979";
    color: #fff;
}

.ft-broker-payment:before {
    content: "\e946";
    color: #fff;
}

.ft-memb-category:before {
    content: "\e9ba";
    color: #fff;
}







.card-footer {
    padding: 0.5rem 0.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0);
}

.card-footer:last-child {
    border-radius: 0 0 -webkit-calc(0.35rem - 1px) -webkit-calc(0.35rem - 1px);
    border-radius: 0 0 -moz-calc(0.35rem - 1px) -moz-calc(0.35rem - 1px);
    border-radius: 0 0 calc(0.35rem - 1px) calc(0.35rem - 1px);
}

.card-footer {
    border-top: 1px solid #EEEEEE;
}

.text-muted {
    color: #000 !important;
}

.info {
    color: #28afd0 !important;
}

.la-arrow-circle-o-up:before {
    content: "\f11c";
}

.float-right {
    float: right !important;
}

.myaccount-inner .ft-user:before {
    content: "\e9d7";
    font-size: 30px;
    color: #fff;
}

.user-username .ft-user:before {
    content: "\e9d7";
    font-size: 30px;
    color: #000;
}
.user-menu .user-username{
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: #2A2E30;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    padding: 10px 20px;
}

.ft-log-out:before{content:"\e973"}
.ft-lock:before{content:"\e971"}

.user-menu.active:after {
    bottom: 100%;
    right: 12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #FFFFFF;
    border-width: 24px;
    margin-left: -16px;
}

.content-header.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.content-wrapper-before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    -webkit-transition: 300ms ease all;
    -o-transition: 300ms ease all;
    -moz-transition: 300ms ease all;
    transition: 300ms ease all;
/*        background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
    background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
    background-image: -moz-linear-gradient(left, #a376fc, #f96f9b);
    background-image: -o-linear-gradient(left, #a376fc, #f96f9b);
    background-image: linear-gradient(to right, #a376fc, #f96f9b);
    background-repeat: repeat-x;*/
    background: #3E3E3C;
}

.mb-2, .my-2 {
    margin-bottom: 1.5rem !important;
}

.content-wrapper .content-header-title {
    font-weight: 700;
    letter-spacing: 1px;
    color: #FFFFFF;
}

.breadcrumb-new .content-header-title {
    padding: 6px 24px 8px 0;
    margin: 0 24px 0 0;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.d-inline-block {
    display: inline-block !important;
}

.mr-1, .mx-1 {
    margin-right: 1rem !important;
}

.breadcrumb {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 0rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #fff;
    border-radius: 0.25rem;
}

.breadcrumb .breadcrumb-item a {
    color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs-top .breadcrumb {
    margin: 0;
    padding: 0;
}

.breadcrumb {
    font-size: 1rem;
    font-family: "Comfortaa", cursive, "Times New Roman", Times, serif;
    background-color: transparent;
    padding: 0 0 0.75rem 0rem;
}

.leaa-admin-head{    margin-top: 70px;}

.car-listing-wrap.leaa-admin-head.card, .form-wrapper.leaa-admin-head.card, .content-body.leaa-admin-head.card{    
    width: 90%;
    margin: 0 auto;
    margin-top: 70px;
    padding: 60px 50px;
}

.pagination{
    white-space: nowrap;
    justify-content: flex-end;
        display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.admin-panel-btn button{margin-left: 20px;border-radius: 5px 5px 5px 5px!important;background-color: #3E3E3C!important;}

/*FT icons*/

.fa-trash:before, .fa-edit:before, .fa-pencil-square-o:before{color: #000;}
.ft-server:before {
    content: "\e9ab";
}
.ft-user-x:before{content:"\e9d6"}
.ft-user:before{content:"\e9d7";color: #fff}
.ft-users:before{content:"\e9d8";color: #fff;}
.ft-edit:before {
    content: "\e94f";
}
.ft-monitor:before {
    content: "\e983";
}
.ft-settings:before{content:"\e9ac"}
.ft-delete:before{content:"\e948"}
.ft-trash-2:before{content:"\e9c7";color: #3E3E3C;}
.ft-edit-3:before{content:"\e94e";color: #3E3E3C;}
.ft-trash-2:before:hover, .ft-edit-3:before:hover{color: #3E3E3C;}
.ft-type:before{content:"\e9ce"}
.ft-sliders:before{content:"\e9b6"}
.ft-feather:before{content:"\e955"}
.ft-briefcase:before{content:"\e91f"}
.actiongroup .ft-unlock:before{content:"\e9d0"; color: #3E3E3C;}
.ft-upload-cloud:before{content:"\e9d1"}
.ft-upload:before{content:"\e9d2";}
.leaa-admin-head .ft-upload:before{content:"\e9d2";margin-left: 10px;}
.ft-eye:before{content:"\e952"}


.ft-membership:before {
    content: "\e914";
}

.ft-tab-management:before {
    content: "\e946";
}

.ft-business-type:before {
    content: "\e91f";
}

.ft-business-category:before {
    content: "\e93c";
}

.ft-loan-config:before {
    content: "\e961";
}

.actiongroup i{color: #3E3E3C;    margin-left: 5px;}
.card-body .ft-users:before{color: #3E3E3C;}

.card-header .card-title {
    background-color: #3e3e3c;
}

.card-header .card-badge {
    color: #FFFFFF;
    font-weight: 400;
        padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.35em 0.4em;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.card-header .card-title-red {
    background-color: #ff0000;
}

.card-header .card-badge-red {
    color: #FFFFFF;
    font-weight: 400;
        padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    display: inline-block;
    padding: 7px 14px;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.card-header .card-title-yellow {
    background-color: #ffc107;
}

.card-header .card-badge-yellow {
    color: #FFFFFF;
    font-weight: 400;
        padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    display: inline-block;
    padding: 7px 14px;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.pl-5{
    padding-left: 50px;
    overflow-y: scroll;
    height: 150px;
    width: 100%;
    resize: none;
}


/*New Theme Resposnive*/

@media(max-width:991px){
    .w-100 label{width: 100%;}
    .site-bg-clr label{font-size: 16px;}
    .admin-panel-btn button{margin: 0px -5px 0px;    top: 4px;}
    .main-menu.menu-fixed{width: 0px;}
    .title h4, .title h3{font-size: 20px;}
    .mobi-close.sidebar-menu{width: 0px;}
    .header-cnt-left.toggle-open{margin-left: 0px;}
    /*.header-cnt-left.toggle-open .menu-icon.active{display: none;}*/
    .menu-close .main-menu.menu-fixed{width: 360px;}
    .navbar-header.expanded{width: 0px;}
    .menu-close .navbar-header.expanded{width: 360px;}
    .menu-close .navbar-header .navbar-brand{display: flex;}
    .menu-close .navbar-header h3.brand-text{display: block;}
    .header-cnt-left{margin-left: 20px;}
    .myaccount-inner{align-items: center;}
    .mobi-close.sidebar-menu + .content{margin-left: 0px;}
    .header .r-icon{margin-right: 0px;}
    .nav .nav-item .nav-link {
    display: block;
    text-decoration: none;
    padding: 1rem 0rem;
    font-size: 24px;
    color: #dcdcdc;
}
.nav .nav-item .nav-link:hover{
    background: none;

}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.nav .nav-item i {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}
.ft-x:before {
    content: "\e9e6";
}
.navbar-header.expanded ul.navbar-nav{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}
li.nav-item.d-md-none{
    display: list-item;
    text-align: -webkit-match-parent;
    line-height: 1.8;
}
    .car-listing-wrap.leaa-admin-head.card, .form-wrapper.leaa-admin-head.card, .content-body.leaa-admin-head.card{margin-top: 35px;}
}

#bt-table tbody tr{
    font-size: 18px;
}

@media(max-width:780px){
.listing-header{display: block;}
.listing-header .add_button {
    padding-bottom: 15px;
}
}
@media(max-width:580px){
.menu-close .main-menu.menu-fixed{width: 100%;}   
.menu-close .navbar-header.expanded{width: 100%;} 
.account-wrapper{width: 400px;padding: 20px;}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.edit.listing{
    display: flex;
}

.listing-thump{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 0px;
}

.listing-thump .thumb{
    flex: 0 0 33.3333%;
    display: flex;
    gap: 15px;
}

.listing-thump .thumb img{
    height: 200px;
    width: 200px;
    object-fit: contain;
}

a span.close{
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 26px;
    font-weight: 600;
    color: #000;
}

/* .form-group .css-g1d714-ValueContainer {
height: 35px;
} */

.form-group .css-14jk2my-containe{
    border: 1px solid #000;
    padding: 15px;
    background: #e5e5e5 !important;
}

.form-group .css-1fhf3k1-control{
    background: transparent !important;
    border-style: unset !important;
}

.form-group .css-g1d714-ValueContainer div{
    background:  transparent !important;
}
.pick-color{text-align: center; width: 13%;}
.table-picker {
    max-width: 161px;
    text-align: center;
}

.ai-feedbtn{margin-top: 15px;}

.hide-font-type{display:none;}
.show-font-type{display:block;}

.form-row-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
}

.form-row-group .form-left, .form-row-group .form-right {
    width: 48%;
}

.form-group .font-list {
    grid-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 0 20px;
}

.form-group .font-list .font-item {
    grid-gap: 10px;
    align-items: center;
    display: flex;
    gap: 10px;
    padding-right: 24px;
    position: relative;
}

.font-item img {
    height: 24px;
    margin-bottom: 0;
    width: 24px;
}

.font-item p {
    margin: 0;
    word-break: break-word;
}

.font-item i{
    color: #000;
}

.flex-align-center {
    align-items: center;
    display: flex;
    gap: 15px;
}